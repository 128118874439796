@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css?family=Playfair+Display:400,500,600,700,800,900|Maven+Pro:400,500,600,700,800,900&display=swap');

.poster {
  z-index: 0;
}

.poster:before {
  background-image: url(https://images.unsplash.com/photo-1492144534655-ae79c964c9d7?ixid=MnwyMDkyMnwwfDF8c2VhcmNofDE0fHxjYXJ8ZW58MHx8fHwxNjMxNjQ3NTY0&ixlib=rb-1.2.1q=85&fm=jpg&crop=faces&cs=srgb&w=1200&fit=max) !important;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  content: " ";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  position: absolute;
}

body.poster:before {
  position: fixed;
}
@media (min-width: 1440px),
  (min-width: 992px) and (-webkit-min-device-pixel-ratio: 2),
  (min-width: 992px) and (min-resolution: 192dpi) {
  .poster:before {
    background-image: url(https://images.unsplash.com/photo-1492144534655-ae79c964c9d7?ixid=MnwyMDkyMnwwfDF8c2VhcmNofDE0fHxjYXJ8ZW58MHx8fHwxNjMxNjQ3NTY0&ixlib=rb-1.2.1q=85&fm=jpg&crop=faces&cs=srgb&w=2000&fit=max) !important;
  }
}

.text-primary-500 {
  --tw-text-opacity: 1;
  color:  rgba(0,128,0,var(--tw-text-opacity));;
}

.bg-primary-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(0,128,0,var(--tw-bg-opacity));
}

.border-primary-500{
  --tw-border-opacity: 1;
  border-color: rgb(0, 128, 0, var(--tw-border-opacity));
}

.hover\:bg-primary-500:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(0, 128, 0, var(--tw-bg-opacity));
}
.hover\:bg-primary-600:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(0, 153, 0, var(--tw-bg-opacity));
}

.react-toastify__toast-container {
  z-index: 9999;
  top: 0;
  /* left: 0; */
  right: 0;
  transform: translateX(-50%);
}

/* const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
}; */

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-100%);
  }
}

.slide-down {
  animation: slideDown 0.5s ease forwards;
}

.slide-up {
  animation: slideUp 0.5s ease forwards;
}

